import React from "react";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { Navigate } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import useAuth from "../hooks/useAuth";
import Loading from "../components/Loading";

const StyledContainer = styled(Container)({
  flex: 1,
  display: "flex",
  justifyContent: "center",
  padding: "2em",
  "& .MuiTypography-root": {
    marginBottom: "1em",
  },
  "& .MuiTextField-root": {
    marginBottom: "1em",
  },
});

const StyledTitle = styled(Typography)({
  fontSize: 24,
  fontWeight: "bold",
  textAlign: "center",
});

const StyledError = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
}));

function Login() {
  const { token, error, signIn, isLoading } = useAuth();
  const [username, setUsername] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [errorUsername, setErrorUsername] = React.useState<boolean>(false);
  const [errorPassword, setErrorPassword] = React.useState<boolean>(false);

  const handleSubmitLogin = React.useCallback(async () => {
    setErrorUsername(!username);
    setErrorPassword(!password);
    if (username && password) {
      await signIn(username, password);
    }
  }, [username, password, signIn]);

  if (token) {
    return <Navigate to="/dashboard" replace />;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <StyledContainer id="sign-in-page" data-testid="sign-in-page">
      <Box
        component="form"
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          rowGap: "1em",
          maxWidth: "400px",
        }}
      >
        <StyledTitle>Sign In</StyledTitle>
        <TextField
          error={errorUsername}
          required
          fullWidth
          id="form-username"
          label="Username"
          helperText={errorUsername ? "Username is required." : null}
          value={username}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setUsername(event.target.value);
          }}
          onKeyDown={(e) => {
            // Automatically focus next textfield on enter
            if (e.code === "Enter") {
              const pwTextField = document.getElementById("form-password");
              pwTextField?.focus();
            }
          }}
        />
        <TextField
          error={errorPassword}
          required
          fullWidth
          id="form-password"
          label="Password"
          type="password"
          helperText={errorPassword ? "Password is required." : null}
          value={password}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(event.target.value);
          }}
          onKeyDown={(e) => {
            // Automatically click submit button on enter
            if (e.code === "Enter") {
              const submitBtn = document.getElementById("form-submit");
              submitBtn?.click();
            }
          }}
        />
        {error && <StyledError>Login failed: {error}</StyledError>}
        <Button
          id="form-submit"
          variant="contained"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={handleSubmitLogin}
        >
          Submit
        </Button>
        <Button
          href="https://mcestarhubolp.ctdi.com/account/forgotpassword"
          target="_blank"
          rel="noopener"
          aria-label="Reset Password in a new window"
          endIcon={<OpenInNewIcon />}
        >
          Reset/Forgot Password
        </Button>
      </Box>
    </StyledContainer>
  );
}

export default Login;
