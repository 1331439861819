import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
} from "@mui/material";
import React from "react";
import dayjs from "dayjs";
import DriversSelector from "./DriversSelector";
import { IDriver } from "../types/IDriver";
import { IVehicleType } from "../types/IVehicleType";
import VehicleTypesSelector from "./VehicleTypesSelector";
import TimeslotsSelector from "./TimeslotsSelector";
import { JobAssignFunc } from "../repositories/Jobs";
import ErrorDialogContentText from "./ErrorDialogContentText";

export interface JobDialogProps {
  jobId: number | undefined;
  setJobId: (x: number | undefined) => void;
}

interface JobAssignDialogProps extends JobDialogProps {
  drivers: IDriver[];
  vehicles: IVehicleType[];
  onAssign: JobAssignFunc;
  reload: () => void;
}

function JobAssignDialog({
  jobId,
  setJobId,
  drivers,
  vehicles,
  onAssign,
  reload,
}: JobAssignDialogProps) {
  const [selectedDriver, setSelectedDriver] = React.useState<string>("");
  const [selectedVehicle, setSelectedVehicle] = React.useState<string>("");
  const [selectedSlot, setSelectedSlot] = React.useState<string>("");
  const [dateToDeliver, setDateToDeliver] = React.useState<string>();
  const [error, setError] = React.useState<string>("");
  const [isSaving, setIsSaving] = React.useState<boolean>(false);

  const resetDeliveryToday = React.useCallback(() => {
    // Input only accept format yyyy-mm-dd
    const today = dayjs().format("YYYY-MM-DD");
    setDateToDeliver(today);
  }, []);

  React.useEffect(() => {
    resetDeliveryToday();
  }, [resetDeliveryToday]);

  const handleAssign = React.useCallback(() => {
    if (
      !jobId ||
      !selectedDriver ||
      !selectedSlot ||
      selectedVehicle === "" ||
      !dateToDeliver
    ) {
      setError(
        "All fields are required. If vehicle is not applicable, choose NA."
      );
      return;
    }

    if (!dayjs(dateToDeliver, "YYYY-MM-DD").isValid()) {
      setError("Date is not in the format YYYY-MM-DD.");
    }

    const formattedDate = dayjs(dateToDeliver, "YYYY-MM-DD").format(
      "MM/DD/YYYY" // Server accepts this format
    );

    setIsSaving(true);
    onAssign(
      jobId,
      selectedDriver,
      selectedSlot,
      selectedVehicle,
      formattedDate
    )
      .then((response) => {
        if (response.status === "success") {
          setSelectedDriver("");
          setSelectedSlot("");
          setSelectedVehicle("");
          setError("");
          setIsSaving(false);
          reload();
          setJobId(undefined);
          resetDeliveryToday();
        } else {
          setError(
            (response.Message as string) ??
              "Server returned error. Please try again."
          );
        }
      })
      .catch(() => setError("Server error, unable to assign job."))
      .finally(() => setIsSaving(false));
  }, [
    jobId,
    selectedDriver,
    selectedSlot,
    selectedVehicle,
    dateToDeliver,
    onAssign,
    reload,
    setJobId,
    resetDeliveryToday,
  ]);

  const handleClose = React.useCallback(() => {
    reload();
    setSelectedDriver("");
    setSelectedSlot("");
    setSelectedVehicle("");
    setError("");
    setJobId(undefined);
    resetDeliveryToday();
  }, [reload, resetDeliveryToday, setJobId]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={!!jobId}
      onClose={handleClose}
      sx={{
        "& .MuiFormControl-root": {
          marginBottom: "1em",
        },
      }}
    >
      <DialogTitle>Assign Job</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <DialogContentText>Job ID: {jobId}</DialogContentText>
        <Divider sx={{ marginBottom: 2 }} />
        <DriversSelector drivers={drivers} onChange={setSelectedDriver} />
        <TimeslotsSelector
          onChange={setSelectedSlot}
          showAllTimeslotOption={false}
        />
        <VehicleTypesSelector
          vehicles={vehicles}
          onChange={setSelectedVehicle}
        />
        <TextField
          fullWidth
          size="small"
          label="Set Delivery Date"
          type="date"
          sx={{ mb: 2 }}
          value={dateToDeliver}
          onChange={(e) => setDateToDeliver(e.target.value)}
          inputProps={{
            min: dayjs().format("YYYY-MM-DD"),
          }}
          InputLabelProps={{ shrink: true }}
          helperText="Date format: DD/MM/YYYYY"
        />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ padding: 2, paddingRight: 3, paddingLeft: 3 }}>
        {isSaving && (
          <CircularProgress size="2rem" sx={{ marginRight: "1rem" }} />
        )}
        {error && <ErrorDialogContentText>{error}</ErrorDialogContentText>}
        <Box sx={{ flex: 1 }} />
        <Button onClick={handleClose} autoFocus disabled={isSaving}>
          Cancel
        </Button>
        <Button onClick={handleAssign} variant="contained" disabled={isSaving}>
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default JobAssignDialog;
