import { IJob } from "../types/IJob";
import Http from "./Http";
import { IJobResponse } from "../types/IJobResponse";
import { IGenericPostResponse } from "../types/IGenericPostResponse";
import { IJobPhoto } from "../types/IJobPhoto";
import { IDownloadResponse } from "../types/IDownloadResponse";
import Config from "./Config";
import { IJobsOptions } from "../types/IJobsOptions";

const config = Config.url();

export type JobAssignFunc = (
  jobId: number,
  driverId: string,
  slot: string,
  vehicleId: string,
  dateToDeliver: string // Format MM/DD/YYYY
) => Promise<IGenericPostResponse>;

export type JobCancelFunc = (
  jobId: number,
  comments: string
) => Promise<IGenericPostResponse>;

export type JobCompleteFunc = (
  jobId: number,
  comments: string,
  files: File[]
) => Promise<IGenericPostResponse>;

export type JobFailedFunc = (
  jobId: number,
  comments: string,
  files: File[]
) => Promise<IGenericPostResponse>;

export type JobCloseFunc = (jobId: number) => Promise<IGenericPostResponse>;

export type JobViewPhotosFunc = (jobId: number) => Promise<IJobPhoto[]>;

export default class Jobs {
  /**
   * Get all jobs
   * For Manager: Get all jobs regardless of driver
   * For Driver: Get all jobs assigned to them only
   * @param token
   * @returns
   */
  static async getAll(options: IJobsOptions): Promise<IJobResponse> {
    if (!options.token) {
      return {
        Pagination: null,
        Data: [],
      };
    }

    const params = new URLSearchParams({
      jobtype: options.jobType || "O",
      sortby: options.sortBy || "",
      orderby: options.orderBy || "ASC",
      page: String(options.page || 1),
      pageSize: String(options.pageSize || 50), // This is unused for now, defaults to 50
    });

    if (options.Ord_Nbr) {
      params.set("Ord_Nbr", options.Ord_Nbr);
    }

    if (options.from_date) {
      params.set("from_date", options.from_date);
    }

    if (options.to_date) {
      params.set("to_date", options.to_date);
    }

    if (options.datetodeliver_from_date) {
      params.set("datetodeliver_from_date", options.datetodeliver_from_date);
    }

    if (options.datetodeliver_to_date) {
      params.set("datetodeliver_to_date", options.datetodeliver_to_date);
    }

    if (options.driver_id) {
      params.set("driver_id", options.driver_id);
    }

    const jobResponse = await Http.get<IJobResponse>(
      options.token,
      `${config.url}/api/jobs?${params.toString()}`
    );

    return jobResponse;
  }

  /**
   * For Manager only: get all jobs by driver ID
   * @param token
   * @param driverId
   * @returns
   */
  static async getAllByDriver(
    token: string,
    driverId: string
  ): Promise<IJob[]> {
    if (!token) {
      return [];
    }

    try {
      const jobResponse = await Http.get<IJob[]>(
        token,
        `${config.url}/api/drivers/${driverId}/jobs`
      );
      return jobResponse;
    } catch (e) {
      return [];
    }
  }

  /**
   * For Manager only: assign driver to job
   * @param token
   * @param jobId
   * @param driverId
   * @param slot
   * @param vehicleId
   * @param dateToDeliver
   * @returns
   */
  static async assign(
    token: string | null,
    jobId: number,
    driverId: string,
    slot: string,
    vehicleId: string,
    dateToDeliver?: string
  ): Promise<IGenericPostResponse> {
    if (!token) {
      return {
        status: "error",
        Success: false,
        Message: "Token cannot be found. Please login again.",
      } as IGenericPostResponse;
    }

    const params = {
      DriverId: driverId,
      TimeSlot: slot,
      VehicleId: Number(vehicleId),
      DateToDeliver: dateToDeliver,
    };
    const url = `${config.url}/api/jobs/${jobId}/assign`;

    const response = await Http.post<IGenericPostResponse>(
      url,
      {
        body: JSON.stringify(params),
      },
      token
    );

    return response;
  }

  /**
   * For Manager only: to cancel a job with comments
   * @param token
   * @param jobId
   * @param comments
   * @returns
   */
  static async cancel(
    token: string | null,
    jobId: number,
    comments: string
  ): Promise<IGenericPostResponse> {
    if (!token) {
      return {
        status: "error",
        Success: false,
        Message: "Token cannot be found. Please login again.",
      } as IGenericPostResponse;
    }

    const formData = new FormData();
    formData.append("Comments", comments);
    const url = `${config.url}/api/jobs/${jobId}/cancel`;

    const response = await Http.postFormData<IGenericPostResponse>(
      url,
      formData,
      token
    );

    return response;
  }

  /**
   * For Driver only: mark job as delivered with comments and file
   * @param token
   * @param jobId
   * @param comments
   * @param file
   * @returns
   */
  static async complete(
    token: string | null,
    jobId: number,
    comments: string,
    files: File[]
  ): Promise<IGenericPostResponse> {
    if (!token) {
      return {
        status: "error",
        Success: false,
        Message: "Token cannot be found. Please login again.",
      } as IGenericPostResponse;
    }

    const formData = new FormData();
    formData.append("Comments", comments);
    files.forEach((f) => {
      formData.append("", f);
    });
    const url = `${config.url}/api/Jobs/${jobId}/delivery`;

    const response = await Http.postFormData<IGenericPostResponse>(
      url,
      formData,
      token
    );

    return response;
  }

  /**
   * For Driver only: mark job as Rejected/Failed with comments and file
   * @param token
   * @param jobId
   * @param comments
   * @param file
   * @returns
   */
  static async failed(
    token: string | null,
    jobId: number,
    comments: string,
    files: File[]
  ): Promise<IGenericPostResponse> {
    if (!token) {
      return {
        status: "error",
        Success: false,
        Message: "Token cannot be found. Please login again.",
      } as IGenericPostResponse;
    }

    const formData = new FormData();
    formData.append("Comments", comments);
    files.forEach((f) => {
      formData.append("", f);
    });
    const url = `${config.url}/api/Jobs/${jobId}/rejection`;

    const response = await Http.postFormData<IGenericPostResponse>(
      url,
      formData,
      token
    );

    return response;
  }

  /**
   * For Manager only: To mark the job as completely closed
   * @param token
   * @param jobId
   * @returns
   */
  static async close(
    token: string | null,
    jobId: number
  ): Promise<IGenericPostResponse> {
    if (!token) {
      return {
        status: "error",
        Success: false,
        Message: "Token cannot be found. Please login again.",
      } as IGenericPostResponse;
    }

    const formData = new FormData();
    const url = `${config.url}/api/jobs/${jobId}/complete`;

    const response = await Http.postFormData<IGenericPostResponse>(
      url,
      formData,
      token
    );

    return response;
  }

  /**
   * Get a list of photos attached to delivered job
   * @param token
   * @param jobId
   * @returns
   */
  static async getJobPhotos(
    token: string | null,
    jobId: number
  ): Promise<IJobPhoto[]> {
    if (!token || !jobId) {
      return [];
    }

    try {
      const jobResponse = await Http.get<IJobPhoto[]>(
        token,
        `${config.url}/api/jobs/${jobId}/pod`
      );
      return jobResponse;
    } catch (e) {
      return [];
    }
  }

  /**
   * For Manager only: Export to Excel
   * @param token string | null
   * @param slot string
   * @param fromDate string
   * @param toDate string
   * @returns
   */
  static async exportToExcel(
    token: string | null,
    slot: string,
    fromDate: string,
    toDate: string
  ): Promise<IDownloadResponse> {
    if (!token) {
      return { blob: null, filename: "" };
    }

    const headers: HeadersInit = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      cache: "no-cache",
    };

    const requestOptions: RequestInit = {
      method: "POST",
      headers,
      body: JSON.stringify({
        TimeSlot: slot,
        FromDate: fromDate,
        ToDate: toDate,
        ExportToExcel: true,
      }),
    };

    const url = `${config.url}/api/Jobs/manager_report`;

    const response = await fetch(url, requestOptions);
    if (!response.ok) {
      throw Error("Server has returned an error.");
    }

    // Filename to be in this convention: report_FromDate_ToDate_Slot
    const constructedName = `${fromDate}_${toDate}_${
      slot === "-" ? "all-slots" : slot.replaceAll(".", "")
    }`;
    const filename = `report_${constructedName.split(" ").join("-")}.xlsx`;

    return {
      blob: await response.blob(),
      filename,
    };
  }

  /**
   * Remove photo from Job
   * @param token
   * @param jobId
   * @param filename
   * @returns boolean
   */
  static async removeJobPhoto(
    token: string,
    jobId: number,
    filename: string
  ): Promise<boolean> {
    if (!token) {
      return false;
    }

    const headers: HeadersInit = {
      Authorization: `Bearer ${token}`,
      cache: "no-cache",
    };

    const requestOptions: RequestInit = {
      method: "DELETE",
      headers,
    };

    const url = `${config.url}/api/Jobs/${jobId}/POD/${filename}`;

    const response = await fetch(url, requestOptions);
    if (!response.ok) {
      throw Error("Server has returned an error.");
    }

    return true;
  }

  /**
   * Upload Photo to job
   * @param token
   * @param jobId
   * @param files
   * @param jobStatus
   * @returns
   */
  static async uploadPhoto(
    token: string | null,
    jobId: number,
    files: File[]
  ): Promise<IGenericPostResponse> {
    if (!token) {
      return {
        status: "error",
        Success: false,
        Message: "Token cannot be found. Please login again.",
      } as IGenericPostResponse;
    }

    const formData = new FormData();
    files.forEach((f) => {
      formData.append("", f);
    });
    const url = `${config.url}/api/Jobs/${jobId}/uploadpod`;

    const response = await Http.postFormData<IGenericPostResponse>(
      url,
      formData,
      token
    );

    return response;
  }
}
