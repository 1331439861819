/* eslint-disable no-void */
import React from "react";
import dayjs from "dayjs";
import { AuthContext } from "../contexts/AuthContext";
import { IJob } from "../types/IJob";
import Jobs from "../repositories/Jobs";
import { IJobType } from "../types/IJobType";
import { IPagination } from "../types/IPagination";

type IUseJobsProps = {
  jobType: IJobType;
  sortBy?: string;
  orderBy: "ASC" | "DESC";
  page: number;
  filterFromDate?: string;
  filterToDate?: string;
  filterDateToDeliverFromDate?: string;
  filterDateToDeliverToDate?: string;
  orderNumber?: string;
  driver?: string;
  timeslot?: string;
};

export default function useJobs({
  jobType = "O",
  sortBy,
  orderBy = "ASC",
  page = 1,
  filterFromDate,
  filterToDate,
  filterDateToDeliverFromDate,
  filterDateToDeliverToDate,
  orderNumber,
  driver,
  timeslot,
}: IUseJobsProps) {
  const { token } = React.useContext(AuthContext);
  const [jobs, setJobs] = React.useState<IJob[]>([]);
  const [pagination, setPagination] = React.useState<IPagination>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");

  /**
   * Reload all jobs
   */
  const reload = React.useCallback(() => {
    if (token) {
      void (async () => {
        setJobs([]);
        setIsLoading(true);

        // Date input format yyyy-mm-dd
        // Change it to format mm/dd/yyyy
        let fromDate = "";
        if (filterFromDate && dayjs(filterFromDate, "YYYY-MM-DD").isValid()) {
          fromDate = dayjs(filterFromDate, "YYYY-MM-DD").format("MM/DD/YYYY");
        }
        let toDate = "";
        if (filterToDate && dayjs(filterToDate, "YYYY-MM-DD").isValid()) {
          toDate = dayjs(filterToDate, "YYYY-MM-DD").format("MM/DD/YYYY");
        }
        let dateToDeliverFromDate = "";
        if (
          filterDateToDeliverFromDate &&
          dayjs(filterDateToDeliverFromDate, "YYYY-MM-DD").isValid()
        ) {
          dateToDeliverFromDate = dayjs(
            filterDateToDeliverFromDate,
            "YYYY-MM-DD"
          ).format("MM/DD/YYYY");
        }
        let dateToDeliverToDate = "";
        if (
          filterDateToDeliverToDate &&
          dayjs(filterDateToDeliverToDate, "YYYY-MM-DD").isValid()
        ) {
          dateToDeliverToDate = dayjs(
            filterDateToDeliverToDate,
            "YYYY-MM-DD"
          ).format("MM/DD/YYYY");
        }

        try {
          const response = await Jobs.getAll({
            token,
            jobType,
            orderBy,
            sortBy,
            page,
            from_date: fromDate,
            to_date: toDate,
            Ord_Nbr: orderNumber,
            driver_id: driver,
            datetodeliver_from_date: dateToDeliverFromDate,
            datetodeliver_to_date: dateToDeliverToDate,
          });
          if (timeslot && timeslot !== "-") {
            setJobs(
              response.Data.filter((ts) => ts.Driver_Timeslot === timeslot)
            );
          } else {
            setJobs(response.Data);
          }
          setPagination(response.Pagination ?? undefined);
        } catch (e) {
          if (e instanceof Error) setError(e.message);
          else setError(String(e));
        }
        setIsLoading(false);
      })();
    }

    return () => {
      setPagination(undefined);
      setIsLoading(false);
      setJobs([]); // Reset to empty array on dismount
    };
  }, [
    token,
    jobType,
    orderBy,
    page,
    filterFromDate,
    filterToDate,
    orderNumber,
    driver,
    timeslot,
    sortBy,
    filterDateToDeliverFromDate,
    filterDateToDeliverToDate,
  ]);

  React.useEffect(() => {
    reload();

    return () => {
      setPagination(undefined);
      setIsLoading(false);
      setJobs([]); // Reset to empty array on dismount
      setError("");
    };
  }, [reload]);

  return {
    error,
    jobs,
    isLoading,
    pagination,
    reload,
  };
}
