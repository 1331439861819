import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
} from "@mui/material";
import React from "react";
import dayjs from "dayjs";
import DriversSelector from "./DriversSelector";
import { IDriver } from "../types/IDriver";
import { IVehicleType } from "../types/IVehicleType";
import VehicleTypesSelector from "./VehicleTypesSelector";
import TimeslotsSelector from "./TimeslotsSelector";
import ErrorDialogContentText from "./ErrorDialogContentText";
import { IGenericPostResponse } from "../types/IGenericPostResponse";

interface JobBulkAssignDialogProps {
  open: boolean;
  setOpen: (o: boolean) => void;
  jobIds: number[];
  drivers: IDriver[];
  vehicles: IVehicleType[];
  onCancel: () => void;
  onBulkAssign: (
    driver: string,
    slot: string,
    vehicleId: string,
    dateToDeliver: string
  ) => Promise<IGenericPostResponse>;
  reload: () => void;
}

function JobBulkAssignDialog({
  open,
  setOpen,
  jobIds,
  drivers,
  vehicles,
  onCancel,
  onBulkAssign,
  reload,
}: JobBulkAssignDialogProps) {
  const [selectedDriver, setSelectedDriver] = React.useState<string>("");
  const [selectedVehicle, setSelectedVehicle] = React.useState<string>("");
  const [selectedSlot, setSelectedSlot] = React.useState<string>("");
  const [dateToDeliver, setDateToDeliver] = React.useState<string>();
  const [error, setError] = React.useState<string>("");
  const [isSaving, setIsSaving] = React.useState<boolean>(false);

  const resetDeliveryToday = React.useCallback(() => {
    // Input only accept format yyyy-mm-dd
    const today = dayjs().format("YYYY-MM-DD");
    setDateToDeliver(today);
  }, []);

  React.useEffect(() => {
    resetDeliveryToday();
  }, [resetDeliveryToday]);

  const handleAssign = React.useCallback(() => {
    if (
      jobIds.length === 0 ||
      !selectedDriver ||
      !selectedSlot ||
      selectedVehicle === "" ||
      !dateToDeliver
    ) {
      setError(
        "All fields are required. If vehicle is not applicable, choose NA."
      );
      return;
    }

    if (!dayjs(dateToDeliver, "YYYY-MM-DD").isValid()) {
      setError("Date is not in the format YYYY-MM-DD.");
    }

    const formattedDate = dayjs(dateToDeliver, "YYYY-MM-DD").format(
      "MM/DD/YYYY" // Server accepts this format
    );

    setIsSaving(true);
    onBulkAssign(selectedDriver, selectedSlot, selectedVehicle, formattedDate)
      .then((response) => {
        if (response.status === "success") {
          setSelectedDriver("");
          setSelectedSlot("");
          setSelectedVehicle("");
          setError("");
          setIsSaving(false);
          reload();
          resetDeliveryToday();
          setOpen(false);
        } else {
          setError(
            (response.Message as string) ??
              "Server returned error. Please try again."
          );
        }
      })
      .catch(() => setError("Server error, unable to assign jobs."))
      .finally(() => setIsSaving(false));
  }, [
    jobIds.length,
    selectedDriver,
    selectedSlot,
    selectedVehicle,
    dateToDeliver,
    onBulkAssign,
    reload,
    resetDeliveryToday,
    setOpen,
  ]);

  const handleClose = React.useCallback(() => {
    onCancel();
    reload();
    setSelectedDriver("");
    setSelectedSlot("");
    setSelectedVehicle("");
    setError("");
    setOpen(false);
    resetDeliveryToday();
  }, [onCancel, reload, resetDeliveryToday, setOpen]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiFormControl-root": {
          marginBottom: "1em",
        },
      }}
    >
      <DialogTitle>Bulk Assign Job</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <DialogContentText>Job IDs: {jobIds.join(",")}</DialogContentText>
        <Divider sx={{ marginBottom: 2 }} />
        <DriversSelector drivers={drivers} onChange={setSelectedDriver} />
        <TimeslotsSelector
          onChange={setSelectedSlot}
          showAllTimeslotOption={false}
        />
        <VehicleTypesSelector
          vehicles={vehicles}
          onChange={setSelectedVehicle}
        />
        <TextField
          fullWidth
          size="small"
          label="Set Delivery Date"
          type="date"
          sx={{ mb: 2 }}
          value={dateToDeliver}
          onChange={(e) => setDateToDeliver(e.target.value)}
          inputProps={{
            min: dayjs().format("YYYY-MM-DD"),
          }}
          InputLabelProps={{ shrink: true }}
          helperText="Date format: DD/MM/YYYYY"
        />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ padding: 2, paddingRight: 3, paddingLeft: 3 }}>
        {isSaving && (
          <CircularProgress size="2rem" sx={{ marginRight: "1rem" }} />
        )}
        {error && <ErrorDialogContentText>{error}</ErrorDialogContentText>}
        <Box sx={{ flex: 1 }} />
        <Button onClick={handleClose} autoFocus disabled={isSaving}>
          Cancel
        </Button>
        <Button onClick={handleAssign} variant="contained" disabled={isSaving}>
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default JobBulkAssignDialog;
