import React from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import JobCancelDialog from "./JobCancelDialog";
import JobAssignDialog from "./JobAssignDialog";
import { IJob } from "../types/IJob";
import AddressBlock from "./AddressBlock";
import { IDriver } from "../types/IDriver";
import { IVehicleType } from "../types/IVehicleType";
import Jobs, {
  JobAssignFunc,
  JobCancelFunc,
  JobCloseFunc,
  JobViewPhotosFunc,
} from "../repositories/Jobs";
import { IJobType } from "../types/IJobType";
import JobCloseDialog from "./JobCloseDialog";
import PhotoDialog from "./PhotoDialog";
import PhotoViewer from "./PhotoViewer";
import { IJobPhoto } from "../types/IJobPhoto";
import PhotoDeleteDialog from "./PhotoDeleteDialog";
import useAuth from "../hooks/useAuth";
import PhotoUploadDialog from "./PhotoUploadDialog";
import { IGenericPostResponse } from "../types/IGenericPostResponse";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  // Disable text wrap
  "& td": { whiteSpace: "nowrap" },
}));

const StyledHeaderRow = styled(TableRow)(({ theme }) => ({
  // Disable text wrap
  "& th": {
    whiteSpace: "nowrap",
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
}));

const ClickableHeaderBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: 1,
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline",
  },
});

interface JobsTableProps {
  bulkAction: boolean;
  jobs: IJob[];
  drivers: IDriver[];
  vehicles: IVehicleType[];
  filteredJobsBy: IJobType;
  sortBy: string;
  orderBy: "ASC" | "DESC";
  onAssign: JobAssignFunc;
  onCancel: JobCancelFunc;
  onClose: JobCloseFunc;
  onViewPhotos: JobViewPhotosFunc;
  onBulkAssign: (jobId: number, checked: boolean) => void;
  onChangeSortBy: (sort: string) => void;
  onChangeOrderBy: (order: "ASC" | "DESC") => void;
  reload: () => void;
}

function JobsTable({
  bulkAction,
  jobs,
  drivers,
  vehicles,
  filteredJobsBy,
  sortBy,
  orderBy,
  onAssign,
  onCancel,
  onClose,
  onViewPhotos,
  onBulkAssign,
  onChangeOrderBy,
  onChangeSortBy,
  reload,
}: JobsTableProps) {
  const { palette } = useTheme();
  const { token } = useAuth();
  const [assignJobId, setAssignJobId] = React.useState<number>();
  const [cancelJobId, setCancelJobId] = React.useState<number>();
  const [closeJobId, setCloseJobId] = React.useState<number>();
  const [photosJobId, setPhotosJobId] = React.useState<number>();

  const [selectedPhoto, setSelectedPhoto] = React.useState<IJobPhoto>();
  const [showSinglePhoto, setShowSinglePhoto] = React.useState<boolean>(false);
  const [showPhotoList, setShowPhotoList] = React.useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] =
    React.useState<boolean>(false);
  const [showUploadDialog, setShowUploadDialog] =
    React.useState<boolean>(false);

  const handleOpenPhotoList = React.useCallback((jobId: number) => {
    setSelectedPhoto(undefined);
    setShowSinglePhoto(false);
    setPhotosJobId(jobId);
    setShowPhotoList(true);
  }, []);

  const handleClosePhotoViewer = React.useCallback(() => {
    setShowSinglePhoto(false);
    setShowPhotoList(true);
  }, []);

  const handleSelectPhoto = React.useCallback((photo: IJobPhoto) => {
    setSelectedPhoto(photo);
    setShowPhotoList(false);
    setShowSinglePhoto(true);
  }, []);

  const handleOpenDeleteDialog = React.useCallback((photo: IJobPhoto) => {
    setSelectedPhoto(photo);
    setShowPhotoList(false);
    setShowDeleteDialog(true);
  }, []);

  const handleCloseDeleteDialog = React.useCallback(() => {
    setSelectedPhoto(undefined);
    setShowDeleteDialog(false);
    setShowPhotoList(true);
  }, []);

  const handleDeletePhoto = React.useCallback(
    async (photo: IJobPhoto): Promise<boolean> => {
      if (token) {
        try {
          const result = await Jobs.removeJobPhoto(
            token,
            photo.Job_Id,
            photo.FileName
          );
          return result;
        } catch (e) {
          return false;
        }
      }

      return false;
    },
    [token]
  );

  const handleOpenUploadDialog = React.useCallback(() => {
    setShowPhotoList(false);
    setShowUploadDialog(true);
  }, []);

  const handleCloseUploadDialog = React.useCallback(() => {
    setShowUploadDialog(false);
    setShowPhotoList(true);
  }, []);

  const handleUploadPhoto = React.useCallback(
    async (jobId: number, files: File[]): Promise<IGenericPostResponse> =>
      Jobs.uploadPhoto(token, jobId, files),
    [token]
  );

  const handleClickSortOrderBy = React.useCallback(
    (newSortBy: string) => {
      if (sortBy !== newSortBy) {
        onChangeSortBy(newSortBy);
      } else {
        onChangeOrderBy(orderBy === "ASC" ? "DESC" : "ASC");
      }
    },
    [onChangeOrderBy, onChangeSortBy, orderBy, sortBy]
  );

  const OrderByIcon = React.useMemo(
    () =>
      orderBy === "DESC" ? (
        <ArrowDownwardIcon sx={{ fontSize: 14 }} />
      ) : (
        <ArrowUpwardIcon sx={{ fontSize: 14 }} />
      ),
    [orderBy]
  );

  return (
    <TableContainer component="div">
      <Table sx={{ minWidth: 650 }} aria-label="jobs table" size="small">
        <TableHead>
          <StyledHeaderRow>
            {["A", "D", "O"].includes(filteredJobsBy) && <TableCell />}
            <TableCell>Program</TableCell>
            <TableCell>Requestor</TableCell>
            <TableCell>
              <ClickableHeaderBox
                onClick={() => handleClickSortOrderBy("created_date")}
              >
                Created Date {sortBy === "created_date" && OrderByIcon}
              </ClickableHeaderBox>
            </TableCell>
            {filteredJobsBy !== "O" && <TableCell>Last Modified</TableCell>}
            <TableCell>Order&nbsp;#</TableCell>
            <TableCell>Ship to</TableCell>
            <TableCell>Job Type</TableCell>
            {!["O", "C"].includes(filteredJobsBy) && (
              <>
                <TableCell>
                  <ClickableHeaderBox
                    onClick={() => handleClickSortOrderBy("datetodeliver")}
                  >
                    Date To Deliver {sortBy === "datetodeliver" && OrderByIcon}
                  </ClickableHeaderBox>
                </TableCell>
                <TableCell>Time slot</TableCell>
                <TableCell>Vehicle Type</TableCell>
                <TableCell>Assigned Driver</TableCell>
              </>
            )}
            {["C", "D", "R", "Z"].includes(filteredJobsBy) && (
              <TableCell>Comments</TableCell>
            )}
            {["D", "R", "Z"].includes(filteredJobsBy) && (
              <TableCell>Uploaded</TableCell>
            )}
          </StyledHeaderRow>
        </TableHead>
        <TableBody>
          {jobs.length > 0 &&
            jobs.map((row) => (
              <StyledTableRow key={row.Id} data-testid="jobs-table-row">
                {["O", "A"].includes(filteredJobsBy) && bulkAction && (
                  <TableCell>
                    <Checkbox
                      onChange={(e) => onBulkAssign(row.Id, e.target.checked)}
                    />
                  </TableCell>
                )}
                {["O"].includes(filteredJobsBy) && !bulkAction && (
                  <TableCell>
                    <ButtonGroup size="small">
                      <Button
                        startIcon={<LocalShippingIcon />}
                        onClick={() => setAssignJobId(row.Id)}
                      >
                        Assign
                      </Button>
                      <Button
                        startIcon={<CancelIcon />}
                        onClick={() => setCancelJobId(row.Id)}
                      >
                        Cancel
                      </Button>
                    </ButtonGroup>
                  </TableCell>
                )}
                {["A"].includes(filteredJobsBy) && !bulkAction && (
                  <TableCell>
                    <ButtonGroup size="small">
                      <Button
                        startIcon={<LocalShippingIcon />}
                        onClick={() => setAssignJobId(row.Id)}
                      >
                        Re-Assign
                      </Button>
                    </ButtonGroup>
                  </TableCell>
                )}
                {filteredJobsBy === "D" && (
                  <TableCell>
                    <ButtonGroup size="small">
                      <Button
                        startIcon={<LocalShippingIcon />}
                        onClick={() => setCloseJobId(row.Id)}
                      >
                        Close
                      </Button>
                    </ButtonGroup>
                  </TableCell>
                )}
                <TableCell component="th" scope="row">
                  {row.Program_Name ?? "N.A."}
                </TableCell>
                <TableCell>{row.Created_By}</TableCell>
                <TableCell>
                  {new Date(row.Created_Date).toLocaleDateString()}
                </TableCell>
                {filteredJobsBy !== "O" && (
                  <TableCell>
                    {new Date(row.Modified_Date).toLocaleDateString()}
                  </TableCell>
                )}
                <TableCell>{row.So_Nbr ?? row.Ord_Nbr}</TableCell>
                <TableCell>
                  <AddressBlock job={row} />
                </TableCell>
                <TableCell>{row.Job_Type}</TableCell>
                {!["O", "C"].includes(filteredJobsBy) && (
                  <>
                    <TableCell>
                      {row.DateToDeliver &&
                        new Date(row.DateToDeliver).toLocaleDateString()}
                    </TableCell>
                    <TableCell>{row.Driver_Timeslot}</TableCell>
                    <TableCell>{row.Vehicle_type}</TableCell>
                    <TableCell>{row.driver_id}</TableCell>
                  </>
                )}
                {["C"].includes(filteredJobsBy) && (
                  <TableCell>{row.Cancel_Comments}</TableCell>
                )}
                {["D", "R", "Z"].includes(filteredJobsBy) && (
                  <>
                    <TableCell>{row.Delivery_Comments}</TableCell>
                    <TableCell>
                      <Button onClick={() => handleOpenPhotoList(row.Id)}>
                        <PhotoLibraryIcon />
                      </Button>
                    </TableCell>
                  </>
                )}
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
      {jobs.length === 0 && (
        <Box
          sx={{
            p: 2,
            width: "100%",
            backgroundColor: palette.grey[100],
          }}
        >
          <Typography>No Job Found</Typography>
        </Box>
      )}
      <JobAssignDialog
        jobId={assignJobId}
        setJobId={setAssignJobId}
        drivers={drivers}
        vehicles={vehicles}
        onAssign={onAssign}
        reload={reload}
      />
      <JobCancelDialog
        jobId={cancelJobId}
        setJobId={setCancelJobId}
        onCancel={onCancel}
        reload={reload}
      />
      <JobCloseDialog
        jobId={closeJobId}
        setJobId={setCloseJobId}
        onClose={onClose}
        reload={reload}
      />
      <PhotoDialog
        jobId={photosJobId}
        setJobId={setPhotosJobId}
        onViewPhotos={onViewPhotos}
        onSelectedPhoto={handleSelectPhoto}
        onSelectedDeletePhoto={handleOpenDeleteDialog}
        onClickUpload={handleOpenUploadDialog}
        visible={showPhotoList}
      />
      {selectedPhoto && (
        <PhotoDeleteDialog
          photo={selectedPhoto}
          visible={showDeleteDialog}
          onClose={() => handleCloseDeleteDialog()}
          onDelete={handleDeletePhoto}
        />
      )}
      {selectedPhoto && (
        <PhotoViewer
          photo={selectedPhoto}
          visible={showSinglePhoto}
          onClose={() => handleClosePhotoViewer()}
        />
      )}
      {photosJobId && (
        <PhotoUploadDialog
          jobId={photosJobId}
          visible={showUploadDialog}
          onClose={handleCloseUploadDialog}
          onUpload={handleUploadPhoto}
        />
      )}
    </TableContainer>
  );
}

export default JobsTable;
